import React from 'react';
import Heading from '../../components/common/Heading';
import './hero.css';

const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="container">
          <Heading
            title="Som Development Construction "
            subtitle="Build your drem properties and see the expectation in your house."
          />
        </div>
      </section>
    </>
  );
};

export default Hero;
