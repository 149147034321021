import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ListingItem from '../components/ListingItem';
import Slider from '../components/Slider/Slider';
import { db } from '../firebase';
import FeaturePojects from '../components/FeaturesSliders/FeatureProjects';
// import Services from '../components/Services';
import Services from '../components/services/Services';
import Stats from '../components/Stats';
import Hero from '../pages/hero/Hero';
import Featured from '../components/home/featured/Featured';
import Award from '../components/home/awards/Awards';
import Testimonials from '../components/Testimonials';
import Career from '../components/Career';
import AboutUs from '../components/AboutUs';

export default function Home() {
  // // State for Offers
  // const [offerListings, setOfferListings] = useState(null);
  // const [loadingOffers, setLoadingOffers] = useState(true); // Add a loading state for offers

  // // State for Rent Listings
  // const [rentListings, setRentListings] = useState(null);
  // const [loadingRent, setLoadingRent] = useState(true); // Add a loading state for rent

  // // State for Sale Listings
  // const [saleListings, setSaleListings] = useState(null);
  // const [loadingSales, setLoadingSales] = useState(true); // Add a loading state for sales

  // // Fetch Offer Listings
  // useEffect(() => {
  //   async function fetchOfferListings() {
  //     try {
  //       const listingsRef = collection(db, 'listings');
  //       const q = query(
  //         listingsRef,
  //         // where('offer', '==', true),
  //         orderBy('timestamp', 'desc'),
  //         limit(4)
  //       );
  //       const querySnap = await getDocs(q);
  //       const listings = [];
  //       querySnap.forEach((doc) => {
  //         listings.push({
  //           id: doc.id,
  //           data: doc.data(),
  //         });
  //       });
  //       setOfferListings(listings);
  //       setLoadingOffers(false); // Stop loading when data is fetched
  //     } catch (error) {
  //       console.error('Error fetching offer listings:', error);
  //     }
  //   }
  //   fetchOfferListings();
  // }, []);

  // // Fetch Rent Listings
  // useEffect(() => {
  //   async function fetchRentListings() {
  //     try {
  //       const listingsRef = collection(db, 'listings');
  //       const q = query(
  //         listingsRef,
  //         where('type', '==', 'rent'),
  //         orderBy('timestamp', 'desc'),
  //         limit(4)
  //       );
  //       const querySnap = await getDocs(q);
  //       const listings = [];
  //       querySnap.forEach((doc) => {
  //         listings.push({
  //           id: doc.id,
  //           data: doc.data(),
  //         });
  //       });
  //       setRentListings(listings);
  //       setLoadingRent(false); // Stop loading when data is fetched
  //     } catch (error) {
  //       console.error('Error fetching rent listings:', error);
  //     }
  //   }
  //   fetchRentListings();
  // }, []);

  // // Fetch Sale Listings
  // useEffect(() => {
  //   async function fetchSaleListings() {
  //     try {
  //       const listingsRef = collection(db, 'listings');
  //       const q = query(
  //         listingsRef,
  //         where('type', '==', 'sale'),
  //         orderBy('timestamp', 'desc'),
  //         limit(4)
  //       );
  //       const querySnap = await getDocs(q);
  //       const listings = [];
  //       querySnap.forEach((doc) => {
  //         listings.push({
  //           id: doc.id,
  //           data: doc.data(),
  //         });
  //       });
  //       setSaleListings(listings);
  //       setLoadingSales(false); // Stop loading when data is fetched
  //     } catch (error) {
  //       console.error('Error fetching sale listings:', error);
  //     }
  //   }
  //   fetchSaleListings();
  // }, []);

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  // };

  return (
    <div>
      <Hero />
      <AboutUs />
      <Featured />
      {/* <Slider /> */}
      <div className="max-w-6xl mx-auto pt-4 space-y-6 bg-white">
        <FeaturePojects />
      </div>
      {/* <Services /> */}
      {/* <Services /> */}
      {/* <Stats /> */}
      {/* <Award /> */}
      <Career />
      <Testimonials />
    </div>
  );
}
