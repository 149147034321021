import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Dialog, Popover } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'; // Import XMarkIcon

import logo from '../assets/somlogo.jpg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  // hook for dynamic sign in and profile
  const [pageState, setPageState] = useState('Sign in');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState('Profile');
      } else {
        setPageState('Sign In');
      }
    });
  }, [auth]);

  const location = useLocation();

  function pathMatchRoute(route) {
    return route === location.pathname;
  }

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      <header className="bg-white shadow-sm sticky top-0 z-40">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-6"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">SomDevelopmentCons</span>
              <img
                className="h-[80px] w-auto rounded-md"
                src={logo}
                alt="logo"
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-blue-800"
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            <a
              href="/"
              className={`text-sm font-semibold leading-6 text-black border-b-[3px] border-b-transparent ${
                pathMatchRoute('/') && 'text-white font-bold border-b-#333'
              }`}
            >
              Home
            </a>
            <a
              href="/projects"
              className={`text-sm font-semibold leading-6 text-black border-b-[3px] border-b-transparent ${
                pathMatchRoute('/projects') &&
                'text-white font-bold border-b-text-custom-blue'
              }`}
            >
              Projects
            </a>

            <a
              href="/services"
              className={`text-sm font-semibold leading-6 text-black border-b-[3px] border-b-transparent ${
                pathMatchRoute('/services') &&
                'text-white font-bold border-b-text-custom-blue'
              }`}
            >
              Services
            </a>
            {/* <a
              href="/career"
              className={`text-sm font-semibold leading-6 text-black border-b-[3px] border-b-transparent ${
                pathMatchRoute('/career') &&
                'text-white font-bold border-b-text-custom-blue'
              }`}
            >
              Career
            </a> */}
            <a
              href="/about"
              className={`text-sm font-semibold leading-6 text-black border-b-[3px] border-b-transparent ${
                pathMatchRoute('/about') &&
                'text-white font-bold border-b-text-custom-blue'
              }`}
            >
              About
            </a>
            <a
              href="/contact"
              className={`text-sm font-semibold leading-6 text-black border-b-[3px] border-b-transparent ${
                pathMatchRoute('/contact') &&
                'text-white font-bold border-b-text-custom-blue'
              }`}
            >
              Contact
            </a>
          </Popover.Group>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-3">
            <ul>
              <li
                className={`cursor-pointer py-1 px-4 rounded-full bg-[#125A94] text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                  (pathMatchRoute('/sign-in') || pathMatchRoute('/profile')) &&
                  'text-white font-bold border-b-blue-500'
                }`}
                onClick={() => navigate('/profile')}
              >
                {pageState}
              </li>
            </ul>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between mt-10">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Som Development</span>
                <img className="h-14 w-auto rounded-md" src={logo} alt="logo" />
              </a>
            </div>
            <div className="mt-1 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-8">
                  <a
                    href="/"
                    className="-mx-3 block rounded-lg mt-2 px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </a>
                  <a
                    href="/projects"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Projects
                  </a>
                  <a
                    href="/services"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Services
                  </a>
                  {/* <a
                    href="/career"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Career
                  </a> */}
                  <a
                    href="/about"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    About
                  </a>
                  <a
                    href="/contact"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Contact
                  </a>
                </div>
                <div className="space-y-2 py-6">
                  <ul>
                    <li
                      className={`cursor-pointer py-1 px-4 rounded-full bg-[#125A94] text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                        (pathMatchRoute('/sign-in') ||
                          pathMatchRoute('/profile')) &&
                        'text-black font-bold border-b-blue-500'
                      }`}
                      onClick={() => {
                        navigate('/profile');
                        toggleMobileMenu();
                      }}
                    >
                      {pageState}
                    </li>
                  </ul>
                </div>
                <button
                  type="button"
                  className="text-gray-500"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <XMarkIcon
                    className="h-8 w-8 bg-blue-700 text-white text-center rounded-full justify-center"
                    aria-hidden="true"
                  />{' '}
                  {/* Close icon */}
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
}
