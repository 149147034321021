import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { db } from '../../firebase';
import Spinner from '../Spinner';
import ListingItem from '../ListingItem';
import Slider from 'react-slick';
import './style.css';
import { Link } from 'react-router-dom';

const FeaturePojects = () => {
  const [listings, setListings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastFetchedListing, setLastFetchedListing] = useState(null);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchListings();
    // fetchCountries();
  }, []);

  // fetch properties
  // useEffect(() => {
  async function fetchListings() {
    try {
      let listingRef = collection(db, 'listings');
      let q = query(
        listingRef,
        // where('listings', '==', true),
        orderBy('timestamp', 'desc')
        // limit(300)
      );

      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchedListing(lastVisible);
      const fetchedListings = [];
      querySnap.forEach((doc) => {
        fetchedListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setListings(fetchedListings);
      setLoading(false);
    } catch (error) {
      toast.error('Could not fetch listing');
    }
  }

  return (
    <div className="max-w-8xl p-4 rounded w-auto justify-center m-auto bg-white mb-8">
      <div className="text-center justify-center mb-8 mt-2">
        <h2 className="px-20 text-3xl mt-6 font-bold uppercase text-gray-800 text-center mb-2">
          Recent Property Listed
        </h2>
        <span className="text-md text-center mt-4">
        Check out our latest properties! If you find one you like, feel free to reach out to us.
        </span>
      </div>
      {/* <Link to="/properties">
        <p
          className="px-20 text-sm text-blue-600 hover:text-blue-800 transition duration-150
              ease-in-out"
        >
          View more properties
        </p>
      </Link> */}
      <div className=" ">
        {loading ? (
          <Spinner />
        ) : listings && listings.length > 0 ? (
          <div className="container ">
            <Slider {...settings}>
              {listings.map((listing) => (
                <ListingItem
                  key={listing.id}
                  id={listing.id}
                  listing={listing.data}
                />
              ))}
            </Slider>
          </div>
        ) : (
          <div className="container">
            <p className="text-bold text-base text-red-600">Sorry! </p>
            <p className="mt-4 text-base text-[#133042]">
              There are no available property
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeaturePojects;
