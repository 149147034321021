// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDE_hiN3_HzIi2dPho5EZWjbVZVXAs8DZY',
  authDomain: 'somdevelopment-5e11d.firebaseapp.com',
  projectId: 'somdevelopment-5e11d',
  storageBucket: 'somdevelopment-5e11d.appspot.com',
  messagingSenderId: '726856297051',
  appId: '1:726856297051:web:48db136adfa8f31c876d7e',
  measurementId: 'G-0FNEQQHFQ3',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore();
