import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  collection,
  getDocs,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';
import { db } from '../firebase';
import Spinner from '../components/Spinner';
import ListingItem from '../components/ListingItem';
import { async } from '@firebase/util';

export default function Properties() {
  const [listings, setListings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastFetchedListing, setLastFetchedListing] = useState(null);
  const [filteredListings, setFilteredListings] = useState(null);

  // Fetch listings and countries
  useEffect(() => {
    fetchListings();
  }, []);

  useEffect(() => {
    setFilteredListings(listings);
  }, [listings]);

  // Fetch listings
  async function fetchListings() {
    try {
      const listingRef = collection(db, 'listings');
      const q = query(
        listingRef,
        // where('type', '==', true),
        orderBy('timestamp', 'desc')
      );

      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchedListing(lastVisible);
      const fetchedListings = [];
      querySnap.forEach((doc) => {
        fetchedListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setListings(fetchedListings);
      setLoading(false);
    } catch (error) {
      toast.error('Could not fetch property');
    }
  }

  // Fetch more listings
  async function onFetchMoreListings() {
    try {
      const listingRef = collection(db, 'listings');
      const q = query(
        listingRef,
        // where('listings', '==', true),
        orderBy('timestamp', 'desc'),
        startAfter(lastFetchedListing)
      );

      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchedListing(lastVisible);
      const fetchedListings = [];
      querySnap.forEach((doc) => {
        fetchedListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setFilteredListings((prevState) => [...prevState, ...fetchedListings]);
      setLoading(false);
    } catch (error) {
      toast.error('Could not fetch listing');
    }
  }

  return (
    <div className="max-w-8xl mx-auto px-8">
      {/* <h1 className="text-3xl text-center mt-6 font-bold mb-6">Properties</h1> */}
      <div class="flex flex-col container">
        <div class="bg-white p-4 mb-10">
          <p>All Projects in Som Development Construction</p>
        </div>
      </div>
      {/* project filter ending */}

      {loading ? (
        <Spinner />
      ) : filteredListings && filteredListings.length > 0 ? (
        <>
          <main>
            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              {filteredListings.map((listing) => (
                <ListingItem
                  key={listing.id}
                  id={listing.id}
                  listing={listing.data}
                />
              ))}
            </ul>
          </main>
          {lastFetchedListing && (
            <div className="flex justify-center items-center">
              <button
                onClick={onFetchMoreListings}
                className="bg-[#133042] self-center px-3 py-1.5 text-white border border-gray-300 mb-6 mt-6 hover:border-slate-600 rounded transition duration-150 ease-in-out"
              >
                See more
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="container">
          <p className="text-bold text-base text-green-600">Sorry! </p>
          <p className="mt-4 text-base text-[#133042]">
            There are no available prjects
          </p>
        </div>
      )}
    </div>
  );
}
