import { Link } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
export default function ListingItem({ listing, id, onEdit, onDelete }) {
  return (
    <li className="relative bg-white flex flex-col justify-between items-center shadow-md hover:shadow-xl rounded-md overflow-hidden transition-shadow duration-150 m-[10px]">
      <Link className="contents" to={`/category/${listing.type}/${id}`}>
        <img
          className="h-[240px] w-full object-cover hover:scale-105 transition-scale duration-200 ease-in"
          loading="lazy"
          src={listing.imgUrls[0]}
          alt=""
        />

        <div className="w-full p-[10px] bg-[#125A94]">
          <p className="font-semibold m-0 text-xl truncate text-white text-center capitalize">
            {listing.name}
          </p>
        </div>
      </Link>
      {onDelete && (
        <FaTrash
          className="absolute bottom-2 right-1 h-[30px] w-[25px] cursor-pointer text-red-600 hover:text-red-800 bg-white p-1 rounded-md"
          onClick={() => onDelete(listing.id)}
        />
      )}
    </li>
  );
}
