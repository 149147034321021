import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./about.css"

const About = () => {
  return (
    <>
      <section className='about'>
        <Back name='Our mission & vission' title='Get to know us ' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Our Mission & Vission' />
            {/* subtitle="Check out our company's vission and mission" */}

            <p><strong>Mission</strong> To be the most respected and successfully operated company in our industry-creating value for all of our stakeholders and deliver high quality solutions in construction and to provide high quality solutions in con-struction, civil engineering and real estate development</p>
            <p> <strong>Vission</strong> At strategic level- we strive to be the contractor of choice and market leadership role to enhance the image of the construction industry
            At social level- to build a brighter future for Somaliland through our work, by contributing tangibly to sustainable economic and social development in the region</p>
            {/* <button className='btn2'>More About Us</button> */}
          </div>
          <div className='right row'>
            <img src='./immio.jpg' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
