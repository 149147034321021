export const nav = [
  {
    text: 'home',
    path: '/',
  },
  {
    text: 'about',
    path: '/about',
  },
  {
    text: 'services',
    path: '/services',
  },
  {
    text: 'projects',
    path: '/projects',
  },
  {
    text: 'blog',
    path: '/blog',
  },
  {
    text: 'pricing',
    path: '/pricing',
  },
  {
    text: 'contact',
    path: '/contact',
  },
];
export const featured = [
  {
    cover: '../images/hero/h6.png',
    name: 'Architectural service',
    
  },
  {
    cover: '../images/hero/h6.png',
    name: 'Construction management',
    // total: '155 Property',
  },
  {
    cover: '../images/hero/h6.png',
    name: 'General construction service',
    // total: '300 Property',
  },
  {
    cover: '../images/hero/h6.png',
    name: 'Estimating and costing of the projects',
    // total: '80 Property',
  },
  {
    cover: '../images/hero/h6.png',
    name: 'scheduling',
    
  },

  {
    cover: '../images/hero/h6.png',
    name: 'Value of engineering',
    
  },

  {
    cover: '../images/hero/h6.png',
    name: 'Life-cycle Analysis',
    
  },

  {
    cover: '../images/hero/h6.png',
    name: 'SCC construction service',
    
  },

  {
    cover: '../images/hero/h6.png',
    name: 'Structural construction',
    
  },

  {
    cover: '../images/hero/h6.png',
    name: 'Excavation of underground buildings',
    
  },

  {
    cover: '../images/hero/h6.png',
    name: 'Asphalt construction service',
    
  },


  {
    cover: '../images/hero/h6.png',
    name: 'SCC construction service',
    // total: '80 Property',
  },


  {
    cover: '../images/hero/h6.png',
    name: 'SCC construction service',
    // total: '80 Property',
  },
];
export const list = [
  {
    id: 1,
    cover: '../images/list/p-1.png',
    name: 'Red Carpet Real Estate',
    location: '210 Zirak Road, Canada',
    category: 'For Rent',
    price: '$3,700',
    type: 'Apartment',
  },
  {
    id: 2,
    cover: '../images/list/p-2.png',
    name: 'Fairmount Properties',
    location: '5698 Zirak Road, NewYork',
    category: 'For Sale',
    price: '$9,750',
    type: 'Condos',
  },
  {
    id: 3,
    cover: '../images/list/p-7.png',
    name: 'The Real Estate Corner',
    location: '5624 Mooker Market, USA',
    category: 'For Rent',
    price: '$5,860',
    type: 'Offices',
  },
  {
    id: 4,
    cover: '../images/list/p-4.png',
    name: 'Herringbone Realty',
    location: '5621 Liverpool, London',
    category: 'For Sale',
    price: '$7,540',
    type: 'Homes & Villas',
  },
  {
    id: 5,
    cover: '../images/list/p-5.png',
    name: 'Brick Lane Realty',
    location: '210 Montreal Road, Canada',
    category: 'For Rent',
    price: '$4,850',
    type: 'Commercial',
  },
  {
    id: 6,
    cover: '../images/list/p-6.png',
    name: 'Banyon Tree Realty',
    location: '210 Zirak Road, Canada',
    category: 'For Sale',
    price: '$2,742',
    type: 'Apartment',
  },
];
export const awards = [
  {
    icon: <i class="fa-solid fa-trophy"></i>,
    num: '320	',
    name: 'Houses',
  },
  {
    icon: <i class="fa-solid fa-briefcase"></i>,
    num: '430',
    name: 'Business Building',
  },
  {
    icon: <i class="fa-solid fa-lightbulb"></i>,
    num: '51',
    name: 'Apartment',
  },
  {
    icon: <i class="fa-solid fa-heart"></i>,
    num: '66',
    name: 'Commercial',
  },
];
export const location = [
  {
    id: 1,
    name: 'New Orleans, Louisiana',
    Villas: '12 Villas',
    Apartments: '10 Apartments',
    Offices: '07 Offices',
    cover: './images/location/city-1.png',
  },
  {
    id: 2,
    name: 'Jerrsy, United State',
    Villas: '12 Villas',
    Apartments: '10 Apartments',
    Offices: '07 Offices',
    cover: './images/location/city-2.png',
  },
  {
    id: 3,
    name: 'Liverpool, London',
    Villas: '12 Villas',
    Apartments: ' 10 Apartments',
    Offices: '07 Offices',
    cover: './images/location/city-3.png',
  },
  {
    id: 4,
    name: 'NewYork, United States',
    Villas: '12 Villas',
    Apartments: ' 10 Apartments',
    Offices: '07 Offices',
    cover: './images/location/city-4.png',
  },
  {
    id: 5,
    name: 'Montreal, Canada',
    Villas: '12 Villas',
    Apartments: ' 10 Apartments',
    Offices: '07 Offices',
    cover: './images/location/city-5.png',
  },
  {
    id: 6,
    name: 'California, USA',
    Villas: '12 Villas',
    Apartments: ' 10 Apartments',
    Offices: '07 Offices',
    cover: './images/location/city-6.png',
  },
];
export const team = [
  {
    list: '50',
    cover: '../images/customer/team-1.jpg',
    address: 'Liverpool, Canada',
    name: 'Sargam S. Singh',
    icon: [
      <i class="fa-brands fa-facebook-f"></i>,
      <i class="fa-brands fa-linkedin"></i>,
      <i class="fa-brands fa-twitter"></i>,
      <i class="fa-brands fa-instagram"></i>,
    ],
  },
  {
    list: '70',
    cover: '../images/customer/team-2.jpg',
    address: 'Montreal, Canada',
    name: 'Harijeet M. Siller',
    icon: [
      <i class="fa-brands fa-facebook-f"></i>,
      <i class="fa-brands fa-linkedin"></i>,
      <i class="fa-brands fa-twitter"></i>,
      <i class="fa-brands fa-instagram"></i>,
    ],
  },
  {
    list: '80',
    cover: '../images/customer/team-3.jpg',
    address: 'Denever, USA',
    name: 'Anna K. Young',
    icon: [
      <i class="fa-brands fa-facebook-f"></i>,
      <i class="fa-brands fa-linkedin"></i>,
      <i class="fa-brands fa-twitter"></i>,
      <i class="fa-brands fa-instagram"></i>,
    ],
  },
  {
    list: '51',
    cover: '../images/customer/team-4.jpg',
    address: '2272 Briarwood Drive',
    name: 'Michael P. Grimaldo',
    icon: [
      <i class="fa-brands fa-facebook-f"></i>,
      <i class="fa-brands fa-linkedin"></i>,
      <i class="fa-brands fa-twitter"></i>,
      <i class="fa-brands fa-instagram"></i>,
    ],
  },
  // {
  //   list: '42',
  //   cover: '../images/customer/team-5.jpg',
  //   address: '2272 Briarwood Drive',
  //   name: 'Michael P. Grimaldo',
  //   icon: [
  //     <i class="fa-brands fa-facebook-f"></i>,
  //     <i class="fa-brands fa-linkedin"></i>,
  //     <i class="fa-brands fa-twitter"></i>,
  //     <i class="fa-brands fa-instagram"></i>,
  //   ],
  // },
  // {
  //   list: '38',
  //   cover: '../images/customer/team-5.jpg',
  //   address: 'Montreal, USA',
  //   name: 'Adam K. Jollio',
  //   icon: [
  //     <i class="fa-brands fa-facebook-f"></i>,
  //     <i class="fa-brands fa-linkedin"></i>,
  //     <i class="fa-brands fa-twitter"></i>,
  //     <i class="fa-brands fa-instagram"></i>,
  //   ],
  // },
];
export const price = [
  {
    plan: 'Basic',
    price: '29',
    ptext: 'per user, per month',
    list: [
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: '99.5% Uptime Guarantee',
      },
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: '120GB CDN Bandwidth',
      },
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: '5GB Cloud Storage',
      },
      {
        change: 'color',
        icon: <i class="fa-solid fa-x"></i>,
        text: 'Personal Help Support',
      },
      {
        change: 'color',
        icon: <i class="fa-solid fa-x"></i>,
        text: 'Enterprise SLA',
      },
    ],
  },
  {
    best: 'Best Value',
    plan: 'Standard',
    price: '49',
    ptext: 'per user, per month',
    list: [
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: '99.5% Uptime Guarantee',
      },
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: '150GB CDN Bandwidth',
      },
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: '10GB Cloud Storage',
      },
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: 'Personal Help Support',
      },
      {
        change: 'color',
        icon: <i class="fa-solid fa-x"></i>,
        text: 'Enterprise SLA',
      },
    ],
  },
  {
    plan: 'Platinum',
    price: '79',
    ptext: '2 user, per month',
    list: [
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: '100% Uptime Guarantee',
      },
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: '200GB CDN Bandwidth',
      },
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: '20GB Cloud Storage',
      },
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: 'Personal Help Support',
      },
      {
        icon: <i class="fa-solid fa-check"></i>,
        text: 'Enterprise SLA',
      },
    ],
  },
];
export const footer = [
  {
    title: 'LAYOUTS',
    text: [
      { list: 'Home Page' },
      { list: 'About Page' },
      { list: 'Service Page' },
      { list: 'Property Page' },
      { list: 'Contact Page' },
      { list: 'Single Blog' },
    ],
  },
  {
    title: 'ALL SECTIONS',
    text: [
      { list: 'Headers' },
      { list: 'Features' },
      { list: 'Attractive' },
      { list: 'Testimonials' },
      { list: 'Videos' },
      { list: 'Footers' },
    ],
  },
  {
    title: 'COMPANY',
    text: [
      { list: 'About' },
      { list: 'Blog' },
      { list: 'Pricing' },
      { list: 'Affiliate' },
      { list: 'Login' },
      { list: 'Changelog' },
    ],
  },
];
