import React from 'react';
import { BsFacebook, BsInstagram, BsTiktok } from 'react-icons/bs';
import logo from '../assets/somlogo.jpg';

const Footer = () => {
  return (
    <footer class="text-gray-600 body-font bg-[#125A94] border-t-[1px] border-gray-50">
      <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <a
          href="/"
          class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
        >
          <img src={logo} alt="" class="w-10 h-10 text-white p-2" />

          <span class="ml-3 text-xl text-white">Som Development</span>
        </a>
        <p class="text-sm text-white sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © 2024 Al Right Reserved by:{' '}
          <a href="/" class="text-yellow-500">
            RCH DESIGN
          </a>
        </p>
        <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
          <a
            href="https://www.facebook.com/Somdevelopmentconstruction?mibextid=LQQJ4d"
            class="text-white"
          >
            <BsFacebook class="w-5 h-5" />
          </a>
          <a
            href="https://www.instagram.com/som_development_c.company?igsh=dWJ1MXZ5NnlyenRw"
            class="ml-3 text-white"
          >
            <BsInstagram class="w-5 h-5" />
          </a>
          <a
            href="https://www.tiktok.com/@somdevelopmentcompany?_t=8qrLU4c1kxh&_r=1"
            class="ml-3 text-white"
          >
            <BsTiktok class="w-5 h-5" />
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
